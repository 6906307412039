import { getAction, postAction, postFormAction,getFileAction } from '@/utils/http.js'//引入封装的get post方法

//样品申请
const requestSample = (params)=>postAction("/api/official/website/services_support/request_sample_send_email",params);
//销售服务
const saleSupport = (params)=>getAction("/api/official/website/services_support/sale_tels",params);
//销售服务-发送邮件
const saleSendEmail = (params)=>postAction("/api/official/website/services_support/sale_seng_email",params);
//技术支持
const techSupport = (params)=>getAction("/api/official/website/services_support/tech_tels",params);
//技术支持-发送邮件
const techSendEmail = (params)=>postAction("/api/official/website/services_support/tech_seng_email",params);
//工程变更通知
const enginChange = (params)=>getAction("/api/official/website/services_support/engineering_change_notification",params);
//变更详情
const enginChangeDetail = (params)=>getAction("/api/official/website/common/getArticleById",params);
//资料下载-产品手册
const productManual = (params)=>getAction("/api/official/website/services_support/product_manual",params);
//资料下载-具体产品
const productItem = (params)=>getAction("/api/official/website/common/relationFiles",params);
//资料下载-开发资源
const developResource = (params)=>getAction("/api/official/website/services_support/develop_resource",params);
//资料下载-工具软件
const toolSoft = (params)=>getAction("/api/official/website/services_support/tool_soft",params);
//资料下载-其他
const other = (params)=>getAction("/api/official/website/services_support/other",params);
//资料下载-视频资料
const videos = (params)=>getAction("/api/official/website/services_support/videos",params);
//资料下载-驱动程序-功能类别 
const featuresSelect = (params)=>getAction("/api/official/website/services_support/product_features",params);
//资料下载-驱动程序-芯片型号 
const productSelect = (params)=>getAction("/api/official/website/services_support/product_feature_product",params);
//资料下载-驱动程序-附件下载列表 
const attachFile = (params)=>getAction("/api/official/website/services_support/product_feature_file",params);
//资料下载-驱动程序-下载 
const downloadFile = (params)=>getFileAction("/api/official/website/common/downloadFile",params);
//搜索页
const searchHttp = (params)=>getAction("/api/official/website/common/search",params);

export {
    requestSample,
    saleSupport,
    saleSendEmail,
    techSupport,
    techSendEmail,
    enginChange,
    enginChangeDetail,
    productManual,
    productItem,
    developResource,
    toolSoft,
    other,
    videos,
    featuresSelect,
    productSelect,
    attachFile,
    downloadFile,
    searchHttp,
}