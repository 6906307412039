<template>
    <div class="container_">
        <div class="topTitle">驱动程序</div>
        <hr  class="hr">
        <div class="content">
            <el-form ref="form" :model="form" label-width="100px">
              <el-row :gutter="0" style="width:100%">
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" style="padding:0px;">
                    <el-form-item label="功能类别：">
                        <el-select v-model="form.feature" :clearable="false"  @change="featureChange"  placeholder="请选择">
                            <el-option
                            v-for="item in featuresSelectArr"
                            :key="item.feature"
                            :label="item.feature"
                            :value="item.feature">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" style="padding:0px;">
                    <el-form-item label="芯片型号：">
                        <el-select v-model="form.product" @change="getRelaList" :clearable="false" placeholder="请选择">
                            <el-option
                            v-for="item in productSelectArr"
                            :key="item.name"
                            :label="item.name"
                            :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
                    <el-button class="searchBtn" type="primary" @click="getRelaList"><i class="el-icon-search"></i> 搜索</el-button>
                </el-col>
              </el-row>
            </el-form>

            <div class="resourcePart" v-loading="loading">
                <div class="title">
                    <span class="text">资料下载</span>
                    <span>（{{relaList&&relaList.length>0?relaList.length:0}}）</span>
                </div>
                <div class="greyBox_container">
                    <div class="greyBox">
                        <div class="thead">
                            <div class="td1">资料名称</div>
                            <div class="td2">资料简介</div>
                            <div class="td3">版本</div>
                            <div class="td4">上传时间</div>
                        </div>
                        <div class="tr" v-for="(item,index) in relaList" :key="index">
                            <div class="td1" @click="clickResourceName(item)">{{item.name}}</div>
                            <div class="td2">{{item.content}}</div>
                            <div class="td3">{{item.version}}</div>
                            <div class="td4">{{item.uploadTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { featuresSelect, productSelect,attachFile} from '@/services/serviceSupport.js'
export default {
  name: "driver",
  components:{
  },
  props:[
  ],
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        form:{},
        featuresSelectArr:[],
        productSelectArr:[],
        relaList:[],
        loading:false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('this.$route.query----',this.$route.query)
    
    if(this.$route.query.feature){
        this.getSelect1()
        this.getSelect2()

         this.form.feature = this.$route.query.feature
        if(this.$route.query.product_name){
            this.form.product = this.$route.query.product_name
        }
        this.$nextTick(()=>{
            this.getRelaList()
        })
    }else{
        this.getFeaturesSelect()
    }
  },
  activated(){
    
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },

    //功能类别
    getFeaturesSelect(){
        this.featuresSelectArr = []
        featuresSelect().then(res => {
            if(res.status===200){
                this.featuresSelectArr = res.data.data
                this.featuresSelectArr.push({
                    feature:'全部'
                })
                if(this.featuresSelectArr&&this.featuresSelectArr[0]){
                    this.form.feature = this.featuresSelectArr&&this.featuresSelectArr[0]?this.featuresSelectArr[0].feature:undefined; //默认第一个
                }
                this.getProductSelect(this.form.feature)

            }else{
                this.$message.error(res.data.message);
            }
        });
    },
    getSelect1(){
        this.featuresSelectArr = []
        featuresSelect().then(res => {
            if(res.status===200){
                this.featuresSelectArr = res.data.data
                this.featuresSelectArr.push({
                    feature:'全部'
                })
            }else{
                this.$message.error(res.data.message);
            }
        });
    },
    
    featureChange(value){
        this.getProductSelect(value)
    },
    //芯片型号
    getProductSelect(feature){
        this.productSelectArr = []
        productSelect({feature:feature=='全部'?'all':feature}).then(res => {
            if(res.status===200){
                this.productSelectArr = res.data.data
                this.productSelectArr.push({
                    name:'全部'
                })
               
                if(this.productSelectArr&&this.productSelectArr[0]){
                    this.form.product = this.productSelectArr&&this.productSelectArr[0]?this.productSelectArr[0].name:undefined; //默认第一个
                    console.log('this.form.product',this.form.product)
                }
                this.$nextTick(()=>{
                    this.getRelaList()
                })
            }else{
                this.$message.error(res.data.message);
            }
        });
    },
    getSelect2(){
        this.productSelectArr = []
        productSelect().then(res => {
            if(res.status===200){
                this.productSelectArr = res.data.data
                this.productSelectArr.push({
                    name:'全部'
                })
            }else{
                this.$message.error(res.data.message);
            }
        });
    },
   
    getRelaList(){
        this.relaList = []
        this.loading = true;
        let params = {
            feature:this.form.feature=='全部'?'all':this.form.feature,
            product:this.form.product=='全部'?'all':this.form.product,
        }
        // this.$route.query.feature = this.form.feature
        // this.$route.query.product_name = this.form.product

        attachFile(params).then(res => {
            if(res.status===200){
                this.relaList = res.data.data
                console.log('relaList---',this.relaList)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    clickResourceName(t){
        let pName = t.name.split('.').join('_')
        this.$router.push({ name: 'ResourceDetail', params: {name:pName+'.html'}})
    },
  
   
  }
};
</script>
<style scoped lang="less">
 .container_ {
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    text-align: left;
    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        font-size: 15px !important;
        color: #333;
        .el-form-item{
            margin-bottom: 10px;
        }
        .searchBtn{
            margin-left: 10%;
        }
        .resourcePart{
            .title{
                height: 32px;
                margin-top: 8px;
                border-bottom: 1px solid #b7dfff;
                margin-bottom: 20px;
                color: #232323;
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                .text{
                    margin: 0;
                    padding-bottom: 6px;
                    min-width: 70px;
                    border-bottom: 2px solid #0275d1;
                    float: left;
                }
            }
            .greyBox_container{
                width: 100%;
            }
            .greyBox{
                background-color: #f5f5f5;
                padding: 15px;
                .thead{
                    display: flex;
                    font-size: 14px;
                    font-weight: 600;
                    border-bottom: 2px solid #ddd;
                }
                .tr{
                    border-bottom: 1px solid #ddd;
                    display: flex;
                    font-size: 15px;
                    .td1{
                       color: #0275d1;
                       cursor: pointer;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
                .td1{
                    width:150px;
                    padding: 8px;
                    word-break: break-all;
                }
                .td2{
                    flex: 1;
                    padding: 8px;
                    word-break: break-all;
                }
                .td3{
                    width:80px;
                    padding: 8px;
                }
                .td4{
                    width:90px;
                    padding: 8px;
                }
            }
        }
    }
 }
@media screen and (max-width: 991px) {
    .greyBox_container{
        overflow-x: auto;
        overflow-y: hidden;
    }
    .greyBox{
        min-width: 700px;
    }
}
@media screen and (max-width: 768px) {
    .container_ .content .searchBtn{
       margin-left: 0px;
    }
    .greyBox{
        padding: 5px !important;
    }
    .greyBox .td1 {
        padding: 4px !important;
        width: 80px !important;
    }
    .greyBox .td2 {
        padding: 4px !important;
        // width: 80px !important;
    }
    .greyBox .td3{
        padding: 4px !important;
    }
    .greyBox .td4{
        padding: 4px !important;
    }
}

</style>